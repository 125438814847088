import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { useShop, useAuth } from 'hooks';
import {
  SkinContainer,
  SkinWrapper,
  SkinImage,
  SkinDescription,
  SkinTier,
  Separator,
} from '../styles';
import { FaSave } from 'react-icons/fa';

export function SkinsNavigationItems({
  icon: Icon,
  active,
  navLabel,
  modeRef,
  modeCallback,
  categoryCallback,
  weaponCallback,
}) {
  const { user } = useAuth();
  const { categories, weapons, inventory, skins } = useShop();

  // const [expanded] = useState(active);
  const [baseCategories, setBaseCategories] = useState([]);
  const [activeCategories, setActiveCategories] = useState([]);
  const [activeWeapon, setActiveWeapon] = useState('');

  const handleActiveCategory = useCallback(
    (ev) => {
      const { category } = ev.currentTarget.dataset;
      let current = [...activeCategories];
      const filteredWeapons = weapons
        .filter((item) => item.category === category)
        .map((item) => item.name);

      if (current.includes(category)) {
        current = current.filter((item) => item !== category);
        if (filteredWeapons.includes(activeWeapon)) {
          setActiveWeapon('');
          categoryCallback();
        }
      } else {
        current = [...current, category];
      }

      setActiveCategories(current);
    },
    [activeCategories, activeWeapon],
  );

  const handleResetSelections = useCallback((ev) => {
    setActiveWeapon('');
    weaponCallback({ same: true });
    setActiveCategories([]);
    modeCallback(ev);
  }, []);

  const handleShowSkins = useCallback(
    (ev) => {
      const { cod } = ev.currentTarget.dataset;
      if (cod == activeWeapon) {
        // setActiveWeapon('');
        // weaponCallback({ same: true });
        return;
      }

      setActiveWeapon(cod);
      weaponCallback(ev);
    },
    [activeWeapon],
  );

  useEffect(() => {
    let filtered = [];
    if (modeRef === 'inventory') {
      filtered = categories.filter((cat) =>
        inventory.map((i) => i.category).includes(cat.category),
      );
    }
    if (modeRef === 'shop') {
      filtered = categories.filter((cat) => {
        let baseTiers = ['none', 'common', 'verified', 'tier1', 'tier2', 'tier3'];
        if (user.tier == 'common') {
          baseTiers = baseTiers.filter((tier) =>
            ['none', 'verified', 'tier1', 'tier2', 'tier3'].includes(tier),
          );
        }
        if (user.tier == 'verified') {
          baseTiers = baseTiers.filter((tier) =>
            ['none', 'tier1', 'tier2', 'tier3'].includes(tier),
          );
        }
        if (user.tier == 'tier1') {
          baseTiers = baseTiers.filter((tier) =>
            ['none', 'tier2', 'tier3'].includes(tier),
          );
        }
        if (user.tier == 'tier2') {
          baseTiers = baseTiers.filter((tier) => ['none', 'tier3'].includes(tier));
        }
        if (user.tier == 'tier3') {
          baseTiers = baseTiers.filter((tier) => ['none'].includes(tier));
        }

        return !baseTiers.includes(cat.tier);
      });
    }

    if (modeRef === 'manage') {
      filtered = categories;
    }

    setBaseCategories(filtered);
  }, [inventory, skins]);

  return (
    <ul className={`space-y-2 ${active ? 'text-yellow-400' : 'text-white'}`}>
      <li>
        <button
          data-mode={modeRef}
          className="flex items-center justify-between p-2 w-full hover:bg-zinc-700"
          onClick={handleResetSelections}
        >
          <div className="flex flex-row items-center gap-x-2">
            <Icon />
            <span>{navLabel}</span>
          </div>
        </button>
        {active &&
          baseCategories.map((cat) => (
            <div
              key={cat.category}
              className={`flex flex-col align-center justify-between ${
                activeCategories.includes(cat.category)
                  ? 'text-yellow-300'
                  : 'text-white'
              }`}
            >
              <button
                className="flex flex-row items-center gap-x-2 hover:bg-zinc-700 p-2 ml-8"
                data-category={cat.category}
                onClick={handleActiveCategory}
              >
                {cat.label}
              </button>
              <div
                className={`flex flex-col align-center ${
                  activeCategories.includes(cat.category) ? '' : 'hidden'
                }`}
              >
                {weapons

                  .filter((weapon) => weapon.category === cat.category)
                  .filter((weapon) => {
                    return (
                      modeRef !== 'inventory' ||
                      inventory.map((i) => i.weapon).includes(weapon.name)
                    );
                  })
                  .map((weapon) => (
                    <button
                      key={weapon.name}
                      data-cod={weapon.name}
                      data-category={weapon.category}
                      className={`flex flex-row items-start hover:bg-zinc-700 px-2 py-1 ml-10 ${
                        activeWeapon === weapon.name
                          ? 'text-yellow-200'
                          : 'text-white'
                      } `}
                      onClick={handleShowSkins}
                    >
                      {weapon.label}
                    </button>
                  ))}
                {/*
                {availableSkins
                .filter((item) => item.category === cat.category)
                .map((subitem) => {
                  const w = [...subitem.weapons];
                  return w.map((weapon) => (
                    <button
                      className="flex flex-row items-start hover:bg-zinc-700 p-2 ml-10"
                      key={weapon.weapon}
                      data-weapon={weapon.weapon}
                      data-category={weapon.category}
                      // onClick={handleShowSkins}
                    >
                      {weapon.label}
                    </button>
                  ));
                })} */}
              </div>
            </div>
          ))}
      </li>
    </ul>
  );
}

SkinsNavigationItems.defaultProps = {
  active: false,
  navLabel: 'Navigation',
};

export function SkinsContentComponent({ mode, weapon, saveCallback }) {
  // const skinsPath = process.env.REACT_APP_BASE_SKIN_PATH;
  const { skins, TierSelect, tiers, inventory } = useShop();
  const { user } = useAuth();

  const [baseSkins, setBaseSkins] = useState(() => {
    return skins;
  });
  const [filteredSkins, setFilteredSkins] = useState([]);
  const [tier, setTier] = useState('');
  const [managedSkins, setManagedSkins] = useState([]);

  useEffect(() => {
    setManagedSkins([]);
  }, [weapon, mode]);

  useEffect(() => {
    let currTier = '';

    let filtered = [];
    if (mode === 'inventory') {
      currTier = user.tier;
      filtered = skins
        .filter((skin) => {
          return inventory.map((i) => i.uuid).includes(skin.uuid);
        })
        .map((skin) => {
          const index = inventory.findIndex((i) => i.uuid === skin.uuid);

          return { ...skin, active: inventory[index].active };
        });
    }
    if (mode === 'shop') {
      currTier = user.tier;
      filtered = skins.filter((skin) => {
        let baseTiers = ['none', 'common', 'verified', 'tier1', 'tier2', 'tier3'];
        if (user.tier == 'common') {
          baseTiers = baseTiers.filter((tier) =>
            ['none', 'verified', 'tier1', 'tier2', 'tier3'].includes(tier),
          );
        }
        if (user.tier == 'verified') {
          baseTiers = baseTiers.filter((tier) =>
            ['none', 'tier1', 'tier2', 'tier3'].includes(tier),
          );
        }
        if (user.tier == 'tier1') {
          baseTiers = baseTiers.filter((tier) =>
            ['none', 'tier2', 'tier3'].includes(tier),
          );
        }
        if (user.tier == 'tier2') {
          baseTiers = baseTiers.filter((tier) => ['none', 'tier3'].includes(tier));
        }
        if (user.tier == 'tier3') {
          baseTiers = baseTiers.filter((tier) => ['none'].includes(tier));
        }
        return !baseTiers.includes(skin.tier.cod);
      });

      filtered = filtered.filter((skin) => {
        return !inventory.map((i) => i.uuid).includes(skin.uuid);
      });
    }
    if (mode === 'manage') {
      filtered = skins.map((skin) => ({ ...skin }));
    }

    setTier(currTier);
    setBaseSkins(filtered);
  }, [mode, skins]);

  useEffect(() => {
    if (!weapon.cod) {
      setFilteredSkins([]);

      return;
    }
    const element = document.getElementById('skinsContainer');

    if (element) {
      element.scrollTop = 0;
    }

    const filtered = baseSkins.filter(
      (item) =>
        item.weapon.category == weapon.category &&
        item.weapon.cod === weapon.cod,
    );

    setFilteredSkins(filtered);
  }, [weapon.cod, baseSkins]);

  useEffect(() => {
    const filtered = baseSkins.filter(
      (item) =>
        item.weapon.category == weapon.category &&
        item.weapon.cod === weapon.cod,
    );
    setFilteredSkins(filtered);
    setManagedSkins([]);
  }, [baseSkins]);

  const handleAdd = useCallback((ev) => {
    const { uuid } = ev.currentTarget.dataset;
    setManagedSkins((state) => {
      if (state.includes(uuid)) {
        return state.filter((s) => s !== uuid);
      } else {
        return [...state, uuid];
      }
    });
  }, []);

  const handleSelect = useCallback(
    (ev) => {
      const { type } = ev.currentTarget.dataset;

      let newManaged = [];
      if (type == 'select') {
        newManaged = filteredSkins.map((item) => item.uuid);
      }

      setManagedSkins(newManaged);
    },
    [managedSkins],
  );

  const handleTierSelection = useCallback((ev) => {
    setTier(ev.target.value);
  }, []);

  const handleSave = useCallback(async () => {
    try {
      const obj = {
        tier: tier,
        skins: managedSkins,
        mode,
      };

      await saveCallback(obj);
    } catch (err) {
      console.log(err);
    }
  }, [managedSkins, tier, mode]);

  const handleUse = useCallback(
    async (ev) => {
      const { uuid, active } = ev.currentTarget.dataset;
      try {
        const obj = {
          tier: tier,
          skins: [uuid],
          mode,
          action: active || false,
        };

        await saveCallback(obj);
      } catch (err) {
        console.log(err);
      }
    },
    [mode, tier],
  );

  const hasWeapon = useMemo(() => {
    return weapon.cod && weapon.cod !== '';
  }, [weapon.cod]);

  return !hasWeapon ? null : (
    <div className="flex flex-col flex-1 overflow-hidden w-full">
      {mode != 'inventory' && (
        <div className="flex flex-row gap-x-2 items-center justify-between px-2 py-1">
          <div className="flex flex-row gap-x-2 mt-3">
            <button
              className="rounded border-2 border-stone-400 text-stone-400 px-2 py-1 hover:border-green-300 hover:text-green-300 disabled:border-stone-500 disabled:text-stone-400 disabled:cursor-not-allowed"
              data-type="select"
              onClick={handleSelect}
              disabled={
                filteredSkins.length === 0 ||
                filteredSkins.length === managedSkins.length
              }
            >
              Selecionar todas
            </button>
            <button
              className="rounded border-2 border-stone-400 text-stone-400 px-2 py-1 hover:border-amber-300 hover:text-amber-300 disabled:border-stone-500 disabled:text-stone-400 disabled:cursor-not-allowed"
              data-type="deselect"
              onClick={handleSelect}
              disabled={filteredSkins.length === 0 || managedSkins.length === 0}
            >
              Remover todas
            </button>
          </div>
          <div>
            <div className="text-stone-300 font-medium">
              {managedSkins.length == 0
                ? 'Nenhuma skin selecionada'
                : `${managedSkins.length} skins selecionadas`}
            </div>
            <div className="flex flex-row items-center gap-x-3">
              {mode === 'manage' && (
                <TierSelect
                  tiers={tiers}
                  changeCallback={handleTierSelection}
                />
              )}

              <button
                disabled={
                  mode === 'manage'
                    ? managedSkins.length === 0 || tier === ''
                    : managedSkins.length === 0
                }
                className="flex flex-row gap-x-2 items-center px-2 py-1 rounded border-2 border-green-500 text-green-500 hover:bg-green-600 hover:text-white disabled:hover:bg-transparent disabled:border-stone-500 disabled:text-stone-400 disabled:cursor-not-allowed"
                onClick={handleSave}
              >
                <FaSave />
                {mode === 'manage' ? 'Salvar' : 'Adicionar ao inventário'}
              </button>
            </div>
          </div>
        </div>
      )}
      <Separator />

      <div
        id="skinsContainer"
        className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-y-3 pl-4 pb-4 w-full overflow-y-auto"
      >
        {filteredSkins.map((skin) => (
          <SkinContainer
            key={skin.uuid}
            onClick={mode != 'inventory' ? handleAdd : handleUse}
            data-uuid={skin.uuid}
            data-active={skin.active}
            active={
              mode != 'inventory'
                ? managedSkins.includes(skin.uuid)
                : skin.active
            }
          >
            <SkinWrapper>
              <SkinImage
                src={`${skin.image}`}
                loading="lazy"
              />
              <SkinDescription className="font-medium text-stone-700">
                {skin.paint.name}
              </SkinDescription>
              {mode == 'manage' && <SkinTier>{skin.tier.label}</SkinTier>}
            </SkinWrapper>
          </SkinContainer>
        ))}
      </div>
    </div>
  );
}
