import React, { useState, useCallback, useEffect } from 'react';

import { Container } from 'components';

import { useNavigate } from 'react-router-dom';
import { maps_assets } from 'assets/map';

import { useMonth } from 'hooks';

import api from 'service';



const matches_stats_key = process.env.REACT_APP_MATCHES;

import './styles.css';

export default function Matches() {
  const { season } = useMonth();
  const navigate = useNavigate();
  // const [currentPage, setCurrentPage] = useState(1);

  const [matches, setMatches] = useState(() => {
    const localStats = localStorage.getItem(matches_stats_key);
    if (localStats) {
      return JSON.parse(localStats);
    }
    return [];
  });

  const getMatches = useCallback(async () => {
    const storedMatches = localStorage.getItem(matches_stats_key);
    const response = await api.get(`/system/matches.php`);
    console.log(response)
    if (
      !storedMatches ||
      (!!storedMatches && JSON.stringify(response) !== storedMatches)
    ) {
      localStorage.setItem(matches_stats_key, JSON.stringify(response));
      setMatches(response);
    } else {
      // console.log('matches_stats -- using cached data');
    }
  }, []);

  useEffect(() => {
    getMatches();
  }, []);

  const handleNavigate = useCallback((ev) => {
    const { uuid } = ev.currentTarget.dataset;
    navigate(`/matches/${uuid}`, {});
  }, []);

  return (
    <Container>
      <div className='container'>
      <div className="mt-10 mb-5 text-lg md:text-2xl text-white w-full md:w-4/6 mx-auto text-center">BNE Premier | Season {season} - {new Date().getFullYear()}</div>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 mt-4'>
          {matches.map((item) => (
            <button key={item.uuid} type='button' className='button-container' onClick={handleNavigate} data-uuid={item.uuid}>
              <div className='div-container'>
                <div>
                  <div className='flex flex-row gap-x-1 justify-start'>
                    <strong>{item.team1.name}</strong>
                    <span>vs</span>
                    <strong>{item.team2.name}</strong>
                  </div>
                  <div className={`flex flex-row gap-x-3 text-start w-48 mt-4 ${item.team1.won ? 'text-emerald-400' : 'text-red-400'}`}>
                    <span>{item.team1.name}</span>
                    <strong>{item.team1.score}</strong>
                  </div>
                  <div className={` flex flex-row gap-x-3 text-start w-48 ${item.team2.won ? 'text-emerald-400' : 'text-red-400'}`}>
                    <span>{item.team2.name}</span>
                    <strong>{item.team2.score}</strong>
                  </div>
                  <div className='mt-2 text-xs text-start'>
                    #{item.matchid}
                  </div>
                </div>
                <div className='flex flex-row justify-end items-start'>
                  <img src={maps_assets[item.map.id].asset} className="w-14 my-0" />
                </div>
              </div>
            </button>
          ))}
        </div>
      </div>
      {/* <Pagination data={matches} maximumItems={20}></Pagination> */}
    </Container>
  );
}
