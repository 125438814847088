export const config = {
  months: [
    { value: 1, label: 'Janeiro', short: 'Jan' },
    { value: 2, label: 'Fevereiro', short: 'Fev' },
    { value: 3, label: 'Março', short: 'Mar' },
    { value: 4, label: 'Abril', short: 'Abr' },
    { value: 5, label: 'Maio', short: 'Mai' },
    { value: 6, label: 'Junho', short: 'Jun' },
    { value: 7, label: 'Julho', short: 'Jul' },
    { value: 8, label: 'Agosto', short: 'Ago' },
    { value: 9, label: 'Setembro', short: 'Set' },
    { value: 10, label: 'Outubro', short: 'Out' },
    { value: 11, label: 'Novembro', short: 'Nov' },
    { value: 12, label: 'Dezembro', short: 'Dez' },
  ],
  seasons: new Map()
};


[
    { cycle: 2, year: 2024, season: 1 },
    { cycle: 3, year: 2024, season: 2 },
    { cycle: 4, year: 2024, season: 3 },
    { cycle: 1, year: 2025, season: 4 },
    { cycle: 2, year: 2025, season: 5 },
    { cycle: 3, year: 2025, season: 6 },
    { cycle: 4, year: 2025, season: 7 },
    { cycle: 1, year: 2026, season: 8 },
    { cycle: 2, year: 2026, season: 9 },
    { cycle: 3, year: 2026, season: 10 },
    { cycle: 4, year: 2026, season: 11 },
    { cycle: 1, year: 2027, season: 12 },
    { cycle: 2, year: 2027, season: 13 },
    { cycle: 3, year: 2027, season: 14 },
    { cycle: 4, year: 2027, season: 15 },
    { cycle: 1, year: 2028, season: 16 },
    { cycle: 2, year: 2028, season: 17 },
    { cycle: 3, year: 2028, season: 18 },
    { cycle: 4, year: 2028, season: 19 },
    { cycle: 1, year: 2029, season: 20 },
    { cycle: 2, year: 2029, season: 21 },
    { cycle: 3, year: 2029, season: 22 },
    { cycle: 4, year: 2029, season: 23 },
    { cycle: 1, year: 2030, season: 24 },
    { cycle: 2, year: 2030, season: 25 },
    { cycle: 3, year: 2030, season: 26 },
    { cycle: 4, year: 2030, season: 27 }
  ].forEach(el => {
    config.seasons.set(`${el.year}-${el.cycle}`, el.season);
  });
