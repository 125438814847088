import React, { createContext, useState, useEffect, useContext } from 'react';

const MonthHook = createContext({});

import { config } from 'configs/config';

function MonthProvider({ children }) {
  const [months] = useState(() => {
    return config.months;
  });

  const [currentMonth] = useState(() => {
    const currMonth = new Date().getUTCMonth() + 1;
    return [...months.filter((month) => month.value === currMonth)][0];
  });

  const [cycle] = useState(() => {
    const currMonth = new Date().getUTCMonth() + 1;
    let currCycle = '';
    if ([1, 2, 3].includes(currMonth)) {
      currCycle = 1;
    }
    if ([4, 5, 6].includes(currMonth)) {
      currCycle = 2;
    }
    if ([7, 8, 9].includes(currMonth)) {
      currCycle = 3;
    }
    if ([10, 11, 12].includes(currMonth)) {
      currCycle = 4;
    }

    // currCycle += '° Trimestre';

    return currCycle;
  });

  const [season] = useState(() => {
    const currYear = new Date().getUTCFullYear();
    return config.seasons.get(`${currYear}-${cycle}`)
  })

  useEffect(() => {}, []);

  return (
    <MonthHook.Provider value={{ months, cycle, currentMonth, season }}>
      {children}
    </MonthHook.Provider>
  );
}

function useMonth() {
  const context = useContext(MonthHook);

  if (!context) {
    throw new Error('useMonth must be used within and MonthProvider');
  }

  return context;
}

export { MonthProvider, useMonth };
