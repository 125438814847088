import ancient from './ancient.png';
import anubis from './anubis.png';
import dust2 from './dust2.png';
import inferno from './inferno.png';
import mirage from './mirage.png';
import nuke from './nuke.png';
import overpass from './overpass.png';
import vertigo from './vertigo.png';
import train from './train.png';

const maps_assets = {
  de_ancient: { name: 'Ancient', cs_name: 'de_ancient', asset: ancient },
  de_anubis: { name: 'Anubis', cs_name: 'de_anubis', asset: anubis },
  de_dust2: { name: 'Dust 2', cs_name: 'de_dust2', asset: dust2 },
  de_inferno: { name: 'Inferno', cs_name: 'de_inferno', asset: inferno },
  de_mirage: { name: 'Mirage', cs_name: 'de_mirage', asset: mirage },
  de_nuke: { name: 'Nuke', cs_name: 'de_nuke', asset: nuke },
  de_overpass: { name: 'Overpass', cs_name: 'de_overpass', asset: overpass },
  de_vertigo: { name: 'Vertigo', cs_name: 'de_vertigo', asset: vertigo },
  de_train: { name: 'Train', cs_name: 'de_train', asset: train },
};
export { maps_assets };
